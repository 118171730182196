/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateSingleViewMutation } from '../../createView/hooks/useCreateSingleViewMutation';
import { useFetchIsViewNameUsed } from '../../viewName/hooks/useFetchIsViewNameUsed';
import { useFetchViewsCounts } from '../../viewsCounts/hooks/useFetchViewsCounts';
import { useDebouncedValue } from '../../cloneView/hooks/useDebouncedValue';
import { useCreateViewModalErrors } from './useCreateViewModalErrors';
import invariant from 'react-utils/invariant';
import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useBETCanCurrentUserMakePublicView } from '../../manageSharing/useBETCanCurrentUserMakePublicView';
export const useCreateViewModalState = ({
  viewObject,
  onSuccess,
  onFailure,
  createStrategy
}) => {
  const {
    data: canMakeViewsPublic = false
  } = useBETCanCurrentUserMakePublicView();
  const [viewSharingUpdates, setViewSharingUpdates] = useState({
    private: viewObject.private || !viewObject.private && !canMakeViewsPublic,
    teamId: viewObject.teamId,
    permissionDetails: viewObject.objectTypeId === TASK_TYPE_ID ? viewObject.permissionDetails : undefined
  });
  const {
    loading: isCountsLoading,
    data: countsData,
    error: isCountsError
  } = useFetchViewsCounts(viewObject.objectTypeId);
  const [textInputValue, setTextInputValue] = useState(createStrategy === 'CREATE_NEW' ? '' : `${viewObject.name} clone`);
  const debouncedName = useDebouncedValue(textInputValue, 300);
  const isDebouncePending = textInputValue !== debouncedName;
  const {
    loading: isNameUsedLoading,
    data: isNameUsedData,
    error: isNameUsedError,
    refetch: isNameUsedRefetch
  } = useFetchIsViewNameUsed({
    objectTypeId: viewObject.objectTypeId,
    viewName: debouncedName
  });
  const isNameUsed = Boolean(isNameUsedData && isNameUsedData.isNameUsed);
  const viewToCreate = useMemo(() => {
    invariant(viewObject.id === undefined, 'you are attempting to create a view with an id - this is not supported');
    invariant(viewObject.ownerId === undefined, 'you are attempting to create a view with an id - this is not supported');
    const merged = Object.assign({}, viewObject, {
      name: textInputValue
    }, viewSharingUpdates);
    if (merged.private === undefined) {
      merged.private = false;
    }
    return merged;
  }, [textInputValue, viewSharingUpdates, viewObject]);
  const [createView, {
    loading: createSingleViewMutationLoading,
    error: createSingleViewMutationError
  }] = useCreateSingleViewMutation(viewToCreate);
  const isLoading = Boolean(isCountsLoading || isDebouncePending || createSingleViewMutationLoading || isNameUsedLoading);
  const isFetchError = Boolean(isCountsError || isNameUsedError);
  const [isError, errorAffordance, errorValidationMessage] = useCreateViewModalErrors({
    isNameEmpty: debouncedName === '',
    isNameUsed,
    viewCountLimit: countsData && countsData.limit,
    viewCountValue: countsData && countsData.currentValue,
    isFetchError,
    isCreateError: Boolean(createSingleViewMutationError),
    createStrategy
  });
  const handleSubmit = useCallback(event => {
    event.preventDefault();
    createView().then(response => {
      if (response.data) {
        onSuccess({
          createdView: response.data.createSingleView
        });
      }
    }).catch(e => {
      onFailure(e);
    });
  }, [createView, onSuccess, onFailure]);
  const textInputOnChange = useCallback(e => {
    setTextInputValue(e.target.value);
  }, []);

  /*
   * every time debouncedName changes, make an api call to see if its used
   */
  useEffect(() => {
    if (debouncedName) {
      isNameUsedRefetch({
        viewName: debouncedName,
        objectTypeId: viewObject.objectTypeId
      });
    }
  }, [debouncedName, isNameUsedRefetch, viewObject.objectTypeId]);
  return {
    isLoading,
    isError,
    errorValidationMessage,
    errorAffordance,
    handleSubmit,
    textInputOnChange,
    textInputValue,
    viewSharingUpdates,
    setViewSharingUpdates
  };
};