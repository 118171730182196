import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { makeQuickFetchRequest } from '../../utils/makeQuickFetchRequest';
export const OBJECT_TYPE_IDS_IN_VIEW_NAMESPACES_QUERY_NAME = 'objectTypeIdsInViewNamespaces';
const queryArguments = ['namespaces'];
const fetcher = ({
  namespaces
}) => makeQuickFetchRequest({
  requestName: OBJECT_TYPE_IDS_IN_VIEW_NAMESPACES_QUERY_NAME,
  makeRequestFn: () => {
    const baseUrl = `sales/v4/views/objectTypeIds`;
    const searchParams = new URLSearchParams(namespaces.map(namespace => ['namespace', namespace]));
    const url = `${baseUrl}?${searchParams.toString()}`;
    return http.get(url);
  }
});
export const ObjectTypeIdsInViewNamespacesQuery = registerQuery({
  fieldName: OBJECT_TYPE_IDS_IN_VIEW_NAMESPACES_QUERY_NAME,
  args: queryArguments,
  fetcher: args => fetcher(args).requestPromise
});
export const useFetchObjectTypeIdsInViewNamespaces = ({
  namespaces,
  skip
}) => useQuery(ObjectTypeIdsInViewNamespacesQuery, {
  variables: {
    namespaces
  },
  skip
});