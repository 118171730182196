import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { transformBackendViewToInternalFormat } from '../dataOperators/backendViews';
import Raven from 'raven-js';
import { usePagingState } from './usePagingState';
import { sanitizeQuery } from '../utils/sanitizeQuery';
import { useObjectTypeIdsInNamespaces } from '../../objectDefinitions/hooks/useObjectTypeIdsInNamespaces';
import { makeQuickFetchRequest } from '../../utils/makeQuickFetchRequest';
export const DEFAULT_PAGE_SIZE = 25;
const BASE_VIEWS_SEARCH_URL = `/sales/v4/views/search`;
const makeViewsSearchUrl = ({
  pagingToken,
  limit
}) => {
  const urlparams = new URLSearchParams();
  if (pagingToken) {
    urlparams.set('pagingToken', pagingToken);
  }
  urlparams.set('limit', String(limit));
  return `${BASE_VIEWS_SEARCH_URL}?${urlparams.toString()}`;
};
export const VIEWS_SEARCH_QUERY_NAME = 'viewsSearch';
export const transformRawViewsSearchResponse = rawResponse => {
  const newResults = rawResponse.results.map(transformBackendViewToInternalFormat);
  return Object.assign({}, rawResponse, {
    results: newResults
  });
};
const fetcher = ({
  viewName,
  ownerIds,
  teamIds,
  objectTypeIds,
  private: isPrivate,
  limit,
  pagingToken,
  sortColumn,
  sortOrder,
  namespace
}) => makeQuickFetchRequest({
  // We only quick fetch the happy path
  requestName: `${VIEWS_SEARCH_QUERY_NAME}-${namespace}-${objectTypeIds === null || objectTypeIds === void 0 ? void 0 : objectTypeIds.join('-')}`,
  makeRequestFn: () => {
    const url = makeViewsSearchUrl({
      limit: limit || DEFAULT_PAGE_SIZE,
      pagingToken
    });
    return http.post(url, {
      //for large portals like 53, the fallback timeout of 15s might not be enough
      //the backend is working on making this search endpoint quicker, but for now we can adjust the timeout
      //of this api call to at least show large portals the info they want, even if it takes longer than
      //we would like
      //more info here https://hubspot.slack.com/archives/C80DDL2N7/p1690567165166129
      timeout: 30000,
      data: {
        viewName,
        ownerIds,
        teamIds,
        objectTypeIds,
        namespace,
        private: isPrivate,
        //private is a reserved js word
        sortColumn: sortColumn || 'name',
        sortOrder: sortOrder || 'ASC'
      }
    });
  }
});
export const ViewsSearchQuery = registerQuery({
  fieldName: VIEWS_SEARCH_QUERY_NAME,
  args: ['viewName', 'ownerIds', 'teamIds', 'objectTypeIds', 'private', 'limit', 'pagingToken', 'sortColumn', 'sortOrder', 'namespace'],
  fetcher: variables => {
    return fetcher(variables).requestPromise.then(transformRawViewsSearchResponse);
  }
});
export const useFetchViewsSearch = externalSearchQueryObject => {
  const {
    currentPagingToken,
    addPagingToken,
    resetPaginationState,
    goToPreviousPage: paginationGoToPreviousPage,
    goToNextPage: paginationGoToNextPage,
    hasNextPage,
    hasPreviousPage,
    previousPagingToken,
    nextPagingToken
  } = usePagingState();
  const [internalQueryObject, setQueryObject] = useState(null);
  const namespaces = useMemo(() => [externalSearchQueryObject.namespace], [externalSearchQueryObject.namespace]);
  const {
    data: defaultObjectTypeIds,
    loading: defaultObjectTypeIdsLoading
  } = useObjectTypeIdsInNamespaces({
    namespaces
  });
  const sanitizedQuery = useMemo(() => sanitizeQuery(externalSearchQueryObject, defaultObjectTypeIds || []), [externalSearchQueryObject, defaultObjectTypeIds]);
  const queryResult = useQuery(ViewsSearchQuery, {
    skip: internalQueryObject === null,
    variables: Object.assign({}, internalQueryObject, {
      pagingToken: currentPagingToken
    }),
    onError: e => {
      Raven.captureException(e, {
        extra: {
          sanitizedQuery,
          internalQueryObject,
          currentPagingToken
        }
      });
    }
    // notifyOnNetworkStatusChange: true, //this will update queryResult.loading whenever we page through
  });
  useEffect(() => {
    if (defaultObjectTypeIdsLoading) {
      return;
    }
    setQueryObject(sanitizedQuery);
    resetPaginationState();
  }, [defaultObjectTypeIdsLoading, sanitizedQuery, resetPaginationState]);

  /**
   * This useEffect looks through the new queryResult and determines if
   * - did the user go to the next page and unconver a new paging token
   *   If so, it appends the new paging token to the array
   * - did the user go to the next page and realize they are at the last page
   *   If so, it appends END_OF_PAGING to the paging tokens array
   **/
  useEffect(() => {
    if (!queryResult.data) {
      return;
    }
    const newPagingToken = queryResult.data.viewsSearch.paging && queryResult.data.viewsSearch.paging.next.after;
    addPagingToken(newPagingToken);
  }, [addPagingToken, queryResult.data]);
  const goToPreviousPage = useCallback(() => {
    if (hasPreviousPage) {
      paginationGoToPreviousPage();
      setQueryObject(Object.assign({}, sanitizedQuery, {
        pagingToken: previousPagingToken
      }));
    }
  }, [sanitizedQuery, hasPreviousPage, paginationGoToPreviousPage, previousPagingToken]);
  const goToNextPage = useCallback(() => {
    if (hasNextPage) {
      paginationGoToNextPage();
      setQueryObject(Object.assign({}, sanitizedQuery, {
        pagingToken: nextPagingToken
      }));
    }
  }, [hasNextPage, sanitizedQuery, paginationGoToNextPage, nextPagingToken]);
  return useMemo(() => Object.assign({}, queryResult, {
    goToNextPage,
    goToPreviousPage,
    hasNextPage,
    hasPreviousPage
  }), [queryResult, goToNextPage, hasNextPage, goToPreviousPage, hasPreviousPage]);
};