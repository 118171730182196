import { parse } from 'hub-http/helpers/params';
import { createSelector } from 'reselect';
import once from 'transmute/once';
import { LegacyBoardGate } from '../../constants/gates';
import { getLinkedInSalesNavigatorId } from '../../constants/marketplaceAppIntegrationIds';
export const userSelector = state => state.auth.user;
export const gatesSelector = state => state.auth.gates;
export const userScopesSelector = state => state.auth.user.scopes;
export const userTeamsSelector = state => state.auth.user.teams;
// Should only be used for Jita-specific logic
export const originalOwnerIdSelector = state => state.auth.originalOwnerId;
export const ownerIdSelector = state => state.auth.ownerId;
export const marketplaceIntegrationsSelector = state => state.auth.marketplaceIntegrations;
export const userIdSelector = createSelector(userSelector, user => user ? user.user_id : user);
export const isJitaUserSelector = createSelector(userSelector, originalOwnerIdSelector, (user, originalOwnerId) => user.email.indexOf('@hubspot.com') > -1 && originalOwnerId === -1);
export const createMarketplaceIntegrationStatusSelector = appId => createSelector(marketplaceIntegrationsSelector, marketplaceIntegrations => marketplaceIntegrations[appId]);
export const createDidFetchMarketplaceIntegrationStatusSelector = appId => createSelector(createMarketplaceIntegrationStatusSelector(appId), status => status !== undefined);
const createHasIntegrationSelector = appId => createSelector(createMarketplaceIntegrationStatusSelector(appId), status => Boolean(status));
export const hasLinkedInIntegrationSelector = createHasIntegrationSelector(getLinkedInSalesNavigatorId());
export const didFetchLinkedInIntegrationStatusSelector = createDidFetchMarketplaceIntegrationStatusSelector(getLinkedInSalesNavigatorId());
export const currentUserAllEmailAddressesSelector = createSelector(userSelector,
// user has a primary email and a list of email addresses
user => [user.email, ...(user.emails || [])]);
export const createHasScopesSelector = scopesToCheck => createSelector(userScopesSelector, usersScopes => {
  const hasMissingScope = Boolean(scopesToCheck.find(curScopeToCheck => !usersScopes.includes(curScopeToCheck)));
  return !hasMissingScope;
});
export const createHasOneOfScopesSelector = scopesToCheck => createSelector(userScopesSelector, userScopes => Boolean(scopesToCheck.find(scope => userScopes.includes(scope))));
function coerceToArray(val) {
  if (!val) {
    return [];
  }
  return Array.isArray(val) ? val : [val];
}
const getGateQueryParams = once(() => {
  const queryParamString = window.location.search;
  // the first character will always be '?' so trim it before parsing
  const queryParams = parse(queryParamString.substring(1));
  const {
    gated,
    ungated
  } = queryParams;
  return {
    gatedToArray: coerceToArray(gated),
    ungatedToArray: coerceToArray(ungated)
  };
});

// Don't use this selector directly in components, create an instance of it below and export that instead
export const _createIsUngatedSelector = gateName => createSelector(gatesSelector, gatesFromState => {
  // This query param check is a quick and simple way to override gates for users
  // For internal use only
  const {
    gatedToArray,
    ungatedToArray
  } = getGateQueryParams();
  if (gatedToArray.includes(gateName)) {
    return false;
  } else if (ungatedToArray.includes(gateName)) {
    return true;
  }
  return gatesFromState.includes(gateName);
});
export const isUngatedForLegacyBoardSelector = _createIsUngatedSelector(LegacyBoardGate);

// Pulled from useCanOverridePermissions
// TODO: Should this `elevated-jita` be using `tasks-support-write`?
// There doesn't appear to ben `elevated-jita` scope.
export const permissionsOverrideSelector = createHasOneOfScopesSelector(['super-admin', 'elevated-jita']);
export const hasViewAllQueuesScope = createHasOneOfScopesSelector(['tasks-view-all-queues']);
export const hasCrmImportScope = createHasOneOfScopesSelector(['crm-import']);