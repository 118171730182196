import { useTheme } from 'foundations-theming/utils';
export const useTypeCSSObject = type => {
  const theme = useTheme();
  switch (type) {
    case 'info':
      return {
        backgroundColor: theme.color['fill-info-subtle'],
        borderInlineColor: theme.color['border-info-default'],
        borderBlockColor: theme.color['border-info-default'],
        borderWidth: theme.borderWidth['100'],
        borderStyle: 'solid',
        color: 'text-core-default'
      };
    case 'system-info':
      return {
        backgroundColor: theme.color['fill-info-subtle'],
        color: 'text-core-default'
      };
    case 'warning':
      return {
        backgroundColor: theme.color['fill-caution-subtle'],
        borderInlineColor: theme.color['border-caution-default'],
        borderBlockColor: theme.color['border-caution-default'],
        borderWidth: theme.borderWidth['100'],
        borderStyle: 'solid',
        color: 'text-core-default'
      };
    case 'system-warning':
      return {
        backgroundColor: theme.color['fill-caution-subtle'],
        color: 'text-core-default'
      };
    case 'danger':
      return {
        backgroundColor: theme.color['fill-alert-subtle'],
        borderInlineColor: theme.color['border-alert-default'],
        borderBlockColor: theme.color['border-alert-default'],
        borderWidth: theme.borderWidth['100'],
        borderStyle: 'solid',
        color: 'text-core-default'
      };
    case 'system-danger':
      return {
        backgroundColor: theme.color['fill-alert-subtle'],
        color: 'text-core-default'
      };
    case 'success':
      return {
        backgroundColor: theme.color['fill-positive-subtle'],
        borderInlineColor: theme.color['border-positive-default'],
        borderBlockColor: theme.color['border-positive-default'],
        borderWidth: theme.borderWidth['100'],
        borderStyle: 'solid',
        color: 'text-core-default'
      };
    case 'tip':
      return {
        backgroundColor: theme.color['fill-surface-default'],
        borderInlineColor: theme.color['border-interactive-default'],
        borderBlockColor: theme.color['border-interactive-default'],
        borderWidth: theme.borderWidth['100'],
        borderStyle: 'solid',
        color: 'text-core-default'
      };
    case 'banner':
      return {
        background: `linear-gradient(89deg, ${theme.color['fill-brand-default']}, ${theme.color['fill-accent-magenta-default']});`,
        color: 'text-primary-on-fill-default'
      };
    default:
      {
        const __exhaustiveCheck = type;
        throw new Error(`Unhandled \`type\` argument:\n ${type}`);
      }
  }
};