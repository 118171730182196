import { useCallback } from 'react';
import { useFetchGeneratedView } from './useFetchGeneratedView';
export const useAiGenerateViewModalState = ({
  viewDescription,
  objectTypeId,
  onClose,
  isRegenerate,
  existingViewId
}) => {
  const {
    generatedView,
    loadingGeneratedView,
    fetchGeneratedView,
    error
  } = useFetchGeneratedView({
    objectTypeId,
    viewDescription,
    isRegenerate,
    existingViewId
  });
  const handleSubmit = useCallback(event => {
    event.preventDefault();
    onClose();
  }, [onClose]);
  return {
    handleSubmit,
    handleGenerateOrUpdate: fetchGeneratedView,
    generatedView,
    loadingGeneratedView,
    error
  };
};