import { AssociationCategoryTypesToIds, HUBSPOT_DEFINED } from 'customer-data-objects/associations/AssociationCategoryTypes';
import { TASK_TO_COMPANY, TASK_TO_CONTACT, TASK_TO_DEAL, TASK_TO_TICKET } from 'customer-data-objects/associations/AssociationTypes';
import AssociationTypeToAssociationTypeId from 'customer-data-objects/associations/AssociationTypeToAssociationTypeId';
import { BODY_PREVIEW, COMPLETION_DATE, CONTACT_TIMEZONE, CREATED_BY, CREATED_DATE, DUE_DATE, LAST_ENGAGEMENT, LAST_MODIFIED_DATE, LAST_TOUCH, OWNER_ASSIGNED_DATE, OWNER_ID, PRIORITY, QUEUE_IDS, REPEAT_STATUS, STATUS, TITLE, TYPE } from 'customer-data-objects/task/TaskPropertyNames';
import { getAssociationColumnKey } from '../utils/associationColumnNames';
import { toCrmObjectAssociationKey } from '../utils/crmObjectHelpers';
import get from '../utils/transmute';
export const BULK_ACTION_LAG_TIME = 2000;
export const DEFAULT_PAGE_SIZE = 25;
export const PAGE_SIZE_OPTIONS = [5, 10, 20, 25, 50, 100];
export const TASKS_TABLE_CLASSNAME = 'tasks-table';
export const COLUMN_NAMES = {
  ASSIGNED_TO: OWNER_ID,
  // TODO: Remove the Associations column name
  ASSOCIATIONS: 'relatesTo',
  CREATED_BY,
  DUE_DATE,
  LAST_ENGAGEMENT: 'lastEngagement',
  LAST_MODIFIED_DATE,
  LAST_TOUCH: 'lastTouch',
  COMPANY_DOMAIN: 'companyDomain',
  COMPANY_INDUSTRY: 'companyIndustry',
  COMPANY_TARGET_ACCOUNT_STATUS: 'companyTargetAccountStatus',
  COMPLETED_AT: COMPLETION_DATE,
  CONTACT_EMAIL: 'contactEmail',
  CONTACT_JOB_TITLE: 'contactJobTitle',
  CONTACT_PHONE_NUMBER: 'contactPhoneNumber',
  CONTACT_TIMEZONE,
  CREATED_AT: CREATED_DATE,
  NOTES: BODY_PREVIEW,
  OWNER_ASSIGNED_DATE,
  PRIORITY,
  REPEAT_STATUS,
  QUEUE_IDS,
  STATUS,
  // ex: TASK_TO_CONTACT = association-0-204,
  TASK_TO_CONTACT: getAssociationColumnKey(AssociationCategoryTypesToIds[HUBSPOT_DEFINED], get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId)),
  TASK_TO_COMPANY: getAssociationColumnKey(AssociationCategoryTypesToIds[HUBSPOT_DEFINED], get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId)),
  TASK_TO_DEAL: getAssociationColumnKey(AssociationCategoryTypesToIds[HUBSPOT_DEFINED], get(TASK_TO_DEAL, AssociationTypeToAssociationTypeId)),
  TASK_TO_TICKET: getAssociationColumnKey(AssociationCategoryTypesToIds[HUBSPOT_DEFINED], get(TASK_TO_TICKET, AssociationTypeToAssociationTypeId)),
  TITLE,
  TYPE
};

// All columns aside from those that are dynamically fetched based on portal/user (such as custom object association columns)
export const ALL_PREDEFINED_COLUMNS = [COLUMN_NAMES.STATUS, COLUMN_NAMES.TITLE, COLUMN_NAMES.COMPLETED_AT, COLUMN_NAMES.CREATED_BY, COLUMN_NAMES.DUE_DATE, COLUMN_NAMES.REPEAT_STATUS, COLUMN_NAMES.LAST_TOUCH, COLUMN_NAMES.LAST_ENGAGEMENT, COLUMN_NAMES.PRIORITY, COLUMN_NAMES.TYPE, COLUMN_NAMES.OWNER_ASSIGNED_DATE, COLUMN_NAMES.ASSIGNED_TO, COLUMN_NAMES.QUEUE_IDS, COLUMN_NAMES.NOTES, COLUMN_NAMES.CREATED_AT, COLUMN_NAMES.LAST_MODIFIED_DATE, COLUMN_NAMES.CONTACT_EMAIL, COLUMN_NAMES.CONTACT_TIMEZONE, COLUMN_NAMES.CONTACT_PHONE_NUMBER, COLUMN_NAMES.CONTACT_JOB_TITLE, COLUMN_NAMES.COMPANY_DOMAIN, COLUMN_NAMES.COMPANY_INDUSTRY, COLUMN_NAMES.COMPANY_TARGET_ACCOUNT_STATUS, COLUMN_NAMES.TASK_TO_CONTACT, COLUMN_NAMES.TASK_TO_COMPANY, COLUMN_NAMES.TASK_TO_DEAL, COLUMN_NAMES.TASK_TO_TICKET];
export const COLUMN_TO_CRM_SEARCH_PROPERTY = {
  [COLUMN_NAMES.ASSIGNED_TO]: {
    searchProperty: OWNER_ID
  },
  [COLUMN_NAMES.COMPLETED_AT]: {
    searchProperty: COMPLETION_DATE
  },
  [COLUMN_NAMES.CONTACT_TIMEZONE]: {
    searchProperty: CONTACT_TIMEZONE
  },
  [COLUMN_NAMES.CREATED_AT]: {
    searchProperty: CREATED_DATE
  },
  [COLUMN_NAMES.CREATED_BY]: {
    searchProperty: CREATED_BY
  },
  [COLUMN_NAMES.DUE_DATE]: {
    searchProperty: DUE_DATE
  },
  [COLUMN_NAMES.LAST_ENGAGEMENT]: {
    searchProperty: LAST_ENGAGEMENT
  },
  [COLUMN_NAMES.LAST_MODIFIED_DATE]: {
    searchProperty: LAST_MODIFIED_DATE
  },
  [COLUMN_NAMES.LAST_TOUCH]: {
    searchProperty: LAST_TOUCH
  },
  [COLUMN_NAMES.NOTES]: {
    searchProperty: BODY_PREVIEW
  },
  [COLUMN_NAMES.OWNER_ASSIGNED_DATE]: {
    searchProperty: OWNER_ASSIGNED_DATE
  },
  [COLUMN_NAMES.PRIORITY]: {
    searchProperty: 'hs_task_priority.displayOrder'
  },
  [COLUMN_NAMES.QUEUE_IDS]: {
    searchProperty: QUEUE_IDS
  },
  [COLUMN_NAMES.TITLE]: {
    searchProperty: TITLE
  },
  [COLUMN_NAMES.TYPE]: {
    searchProperty: TYPE
  }
};
const OBJECT_TYPE_TO_ASSOCIATION_KEY = {
  [TASK_TO_CONTACT]: toCrmObjectAssociationKey({
    associationCategoryId: AssociationCategoryTypesToIds[HUBSPOT_DEFINED],
    associationTypeId: get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId)
  }),
  [TASK_TO_COMPANY]: toCrmObjectAssociationKey({
    associationCategoryId: AssociationCategoryTypesToIds[HUBSPOT_DEFINED],
    associationTypeId: get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId)
  }),
  [TASK_TO_DEAL]: toCrmObjectAssociationKey({
    associationCategoryId: AssociationCategoryTypesToIds[HUBSPOT_DEFINED],
    associationTypeId: get(TASK_TO_DEAL, AssociationTypeToAssociationTypeId)
  }),
  [TASK_TO_TICKET]: toCrmObjectAssociationKey({
    associationCategoryId: AssociationCategoryTypesToIds[HUBSPOT_DEFINED],
    associationTypeId: get(TASK_TO_TICKET, AssociationTypeToAssociationTypeId)
  })
};

// { association-0-204: { associationCategoryId-associationTypeId: [list of properties] } }
export const COLUMN_TO_HYDRATION_ASSOCIATION_SPECS = {
  [COLUMN_NAMES.TASK_TO_CONTACT]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_CONTACT]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId),
      properties: ['firstname', 'lastname', 'email']
    }
  },
  [COLUMN_NAMES.TASK_TO_COMPANY]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_COMPANY]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId),
      properties: ['name', 'domain']
    }
  },
  [COLUMN_NAMES.TASK_TO_DEAL]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_DEAL]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_DEAL, AssociationTypeToAssociationTypeId),
      properties: ['dealname']
    }
  },
  [COLUMN_NAMES.TASK_TO_TICKET]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_TICKET]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_TICKET, AssociationTypeToAssociationTypeId),
      properties: ['subject']
    }
  },
  [COLUMN_NAMES.CONTACT_EMAIL]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_CONTACT]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId),
      properties: ['email']
    }
  },
  [COLUMN_NAMES.CONTACT_PHONE_NUMBER]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_CONTACT]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId),
      properties: ['phone']
    }
  },
  [COLUMN_NAMES.CONTACT_JOB_TITLE]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_CONTACT]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_CONTACT, AssociationTypeToAssociationTypeId),
      properties: ['jobtitle']
    }
  },
  [COLUMN_NAMES.COMPANY_DOMAIN]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_COMPANY]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId),
      properties: ['domain']
    }
  },
  [COLUMN_NAMES.COMPANY_INDUSTRY]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_COMPANY]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId),
      properties: ['industry']
    }
  },
  [COLUMN_NAMES.COMPANY_TARGET_ACCOUNT_STATUS]: {
    [OBJECT_TYPE_TO_ASSOCIATION_KEY[TASK_TO_COMPANY]]: {
      associationCategory: HUBSPOT_DEFINED,
      associationTypeId: get(TASK_TO_COMPANY, AssociationTypeToAssociationTypeId),
      properties: ['hs_is_target_account']
    }
  }
};
export const CUSTOM_COLUMN_WIDTHS = {
  [COLUMN_NAMES.ASSIGNED_TO]: 220,
  [COLUMN_NAMES.COMPANY_INDUSTRY]: 250,
  [COLUMN_NAMES.COMPLETED_AT]: 220,
  [COLUMN_NAMES.CREATED_AT]: 220,
  [COLUMN_NAMES.DUE_DATE]: 220,
  [COLUMN_NAMES.LAST_ENGAGEMENT]: 200,
  [COLUMN_NAMES.LAST_TOUCH]: 200,
  [COLUMN_NAMES.NOTES]: 200,
  [COLUMN_NAMES.PRIORITY]: 150,
  [COLUMN_NAMES.STATUS]: 100,
  [COLUMN_NAMES.TASK_TO_COMPANY]: 250,
  [COLUMN_NAMES.TASK_TO_CONTACT]: 250,
  [COLUMN_NAMES.TASK_TO_DEAL]: 250,
  [COLUMN_NAMES.TASK_TO_TICKET]: 250,
  [COLUMN_NAMES.TITLE]: 400,
  [COLUMN_NAMES.TYPE]: 150
};
export const MINIMUM_COLUMN_WIDTH = 100;
export const DEFAULT_COLUMN_WIDTH = 300;
export const STATIC_COLUMNS_FOR_OPEN_VIEWS = [COLUMN_NAMES.STATUS, COLUMN_NAMES.TITLE];
export const DEFAULT_USER_SETTING_COLUMNS = [COLUMN_NAMES.TASK_TO_CONTACT, COLUMN_NAMES.TASK_TO_COMPANY, COLUMN_NAMES.LAST_TOUCH, COLUMN_NAMES.LAST_ENGAGEMENT, COLUMN_NAMES.TYPE, COLUMN_NAMES.DUE_DATE];
export const DEFAULT_COLUMNS_FOR_SAVED_VIEWS = [...STATIC_COLUMNS_FOR_OPEN_VIEWS, ...DEFAULT_USER_SETTING_COLUMNS].map(name => ({
  name
}));

// These constants determine order in which columns appear in their category in the column editor
const TASK_PROPERTIES = [COLUMN_NAMES.STATUS, COLUMN_NAMES.TITLE, COLUMN_NAMES.COMPLETED_AT, COLUMN_NAMES.DUE_DATE, COLUMN_NAMES.REPEAT_STATUS, COLUMN_NAMES.PRIORITY, COLUMN_NAMES.TYPE, COLUMN_NAMES.QUEUE_IDS, COLUMN_NAMES.ASSIGNED_TO, COLUMN_NAMES.NOTES, COLUMN_NAMES.CREATED_AT, COLUMN_NAMES.CREATED_BY, COLUMN_NAMES.LAST_MODIFIED_DATE, COLUMN_NAMES.OWNER_ASSIGNED_DATE];
const ACTIVITY_PROPERTIES = [COLUMN_NAMES.LAST_TOUCH, COLUMN_NAMES.LAST_ENGAGEMENT];
const ASSOCIATION_PROPERTIES = [COLUMN_NAMES.TASK_TO_CONTACT, COLUMN_NAMES.TASK_TO_COMPANY, COLUMN_NAMES.TASK_TO_TICKET, COLUMN_NAMES.TASK_TO_DEAL];
const ASSOCIATION_CONTACT_PROPERTIES = [COLUMN_NAMES.CONTACT_EMAIL, COLUMN_NAMES.CONTACT_PHONE_NUMBER, COLUMN_NAMES.CONTACT_JOB_TITLE, COLUMN_NAMES.CONTACT_TIMEZONE];
const ASSOCIATION_COMPANY_PROPERTIES = [COLUMN_NAMES.COMPANY_DOMAIN, COLUMN_NAMES.COMPANY_INDUSTRY, COLUMN_NAMES.COMPANY_TARGET_ACCOUNT_STATUS];
export const COLUMN_PROPERTY_CATEGORY_GROUPS = {
  taskProperties: TASK_PROPERTIES,
  activity: ACTIVITY_PROPERTIES,
  associations: ASSOCIATION_PROPERTIES,
  associatedContact: ASSOCIATION_CONTACT_PROPERTIES,
  associatedCompany: ASSOCIATION_COMPANY_PROPERTIES
};

/**
 * Represents different ways a user can affect a task
 * Each action encompasses the single action (ex: completing one task)
 * and the bulk action (ex: completing 10 tasks)
 *
 * Used to determine when to exclude taskIds from the current view
 */
export const TASK_INTERACTION_TYPES = {
  COMPLETE_TASKS: 'COMPLETE_TASKS',
  UNCOMPLETE_TASKS: 'UNCOMPLETE_TASKS',
  DELETE_TASKS: 'DELETE_TASKS',
  FOLLOW_UP_TASK: 'FOLLOW_UP_TASK'
};
export const BULK_COMPLETE_TASKS_THRESHOLD = 20;