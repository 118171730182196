import styled, { css } from 'styled-components';
const normalOutline = css(["box-shadow:inset 0 0 0 ", " ", ";"], ({
  $theme
}) => $theme.space['25'], ({
  $theme
}) => $theme.color['border-core-hover']);
const activeHighlight = css(["box-shadow:inset 0 0 0 ", " ", ";"], ({
  $theme
}) => $theme.space['25'], ({
  $theme
}) => $theme.color['fill-info-default']);
const errorHighlight = css(["box-shadow:inset 0 0 0 ", " ", ";"], ({
  $theme
}) => $theme.space['25'], ({
  $theme
}) => $theme.color['fill-alert-default']);
export const EditCellFloatyWrapper = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellFloatyWrapper",
  componentId: "sc-1sybp2n-0"
})(["position:relative;display:flex;min-height:100%;max-width:100%;flex-direction:column;align-items:flex-start;background:", ";box-shadow:0 0 ", " ", ";"], ({
  $theme
}) => getFillSecondaryDefault($theme), ({
  $theme
}) => $theme.space['200'], ({
  $theme
}) => $theme.color['border-core-hover']);
const editCellPadding = css(["padding-inline:", ";padding-block:", ";"], ({
  $theme
}) => $theme.space['600'], ({
  $theme
}) => $theme.space['50']);

// When a cell is in an error state, it contains validation text that is intentionally
// hidden through overflow. The EditCellFloatyWrapper becomes taller than the cell's
// visible area, but we only want to show the input portion. Since the overflow is
// hidden, only the top border of the container remains visible. To maintain visual
// consistency, we need to reduce the min-height by the bottom border width
// (borderWidth['100']).
const heightOfTdWithError = css(["min-height:calc( ", " - ", " );"], ({
  $theme
}) => $theme.space['1000'], ({
  $theme
}) => $theme.borderWidth['100']);
const heightOfTd = css(["min-height:calc( ", " - ", " * 2 );"], ({
  $theme
}) => $theme.space['1000'], ({
  $theme
}) => $theme.borderWidth['100']);
export const EditCellPropertyInputWrapper = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellPropertyInputWrapper",
  componentId: "sc-1sybp2n-1"
})(["", " ", " flex-grow:1;width:100%;background:", ";", " display:flex;align-items:center;overflow:hidden;z-index:1;"], editCellPadding, props => props['aria-invalid'] ? heightOfTdWithError : heightOfTd, ({
  $theme
}) => getFillSecondaryDefault($theme), props => props['aria-invalid'] ? errorHighlight : normalOutline);
export const EditCellContainer = styled.div.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellContainer",
  componentId: "sc-1sybp2n-2"
})(["flex-grow:1;"]);
export const EditCellErrorText = styled.small.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellErrorText",
  componentId: "sc-1sybp2n-3"
})(["", " color:", ";"], editCellPadding, ({
  $theme
}) => $theme.color['fill-alert-default']);
const editCellStyle = css(["display:block;padding:", " !important;", " textarea,input{height:auto;min-height:auto;font-size:", ";padding:", ";border:", ";}"], ({
  $theme
}) => $theme.space['0'], activeHighlight, ({
  $theme
}) => $theme.typography['body-100'].fontSize, ({
  $theme
}) => $theme.space['0'], ({
  $theme
}) => $theme.borderWidth['0']);
export const EditCell = styled.td.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCell",
  componentId: "sc-1sybp2n-4"
})(["", ""], editCellStyle);
export const EditCellSpan = styled.span.withConfig({
  displayName: "StyledInlineEditCellComponents__EditCellSpan",
  componentId: "sc-1sybp2n-5"
})(["", ""], editCellStyle);

// FIXME: The correct semantic token for the background color
// is fill-secondary-default, but there's currently a bug in the
// theme engine that causes the background color to be incorrect.
// Once the bug is fixed, we should use fill-secondary-default instead.
// See: https://git.hubteam.com/HubSpot/foundations-design-eng/issues/371
function getFillSecondaryDefault(theme) {
  return theme.color['fill-field-pressed'];
}