import { useLazyQuery } from 'data-fetching-client';
import { useMemo, useCallback } from 'react';
import { registerQuery } from 'data-fetching-client';
import * as References from '../../../__generated__/chirp/com/hubspot/index/page/copilot/rpc/ViewGenerationService';
import { createRpcClientV2 } from 'rpc-client-utils';
import http from 'hub-http/clients/apiClient';
import Raven from 'raven-js';
const chirpClient = createRpcClientV2({
  hubHttpClient: http
});
export const GENERATE_VIEW_AND_CREATE_WITH_COMBINED_LLM_MODULES_QUERY = registerQuery({
  fieldName: 'generateViewAndCreateWithCombinedLlmModules',
  args: ['viewDescription', 'objectTypeId'],
  fetcher(request) {
    return chirpClient.call(References.generateViewAndCreateWithCombinedLlmModulesRpc, {
      request,
      timeout: 20000
    });
  }
});
export const GENERATE_VIEW_AND_UPDATE_WITH_COMBINED_LLM_MODULES_QUERY = registerQuery({
  fieldName: 'generateViewAndUpdateWithCombinedLlmModules',
  args: ['viewId', 'viewDescription', 'objectTypeId'],
  fetcher(request) {
    return chirpClient.call(References.generateViewAndUpdateWithCombinedLlmModulesRpc, {
      request,
      timeout: 20000
    });
  }
});
export const useFetchGeneratedView = ({
  objectTypeId,
  viewDescription,
  isRegenerate = false,
  existingViewId
}) => {
  const [lazyFetchCreate, {
    data: createData,
    loading: createLoading,
    error: createError
  }] = useLazyQuery(GENERATE_VIEW_AND_CREATE_WITH_COMBINED_LLM_MODULES_QUERY, {
    variables: {
      viewDescription,
      objectTypeId
    },
    onError: error => {
      Raven.captureException(new Error('Create Ai view query failed'), {
        extra: {
          viewDescription,
          objectTypeId,
          error
        }
      });
    }
  });
  const [lazyFetchUpdate, {
    data: updateData,
    loading: updateLoading,
    error: updateError
  }] = useLazyQuery(GENERATE_VIEW_AND_UPDATE_WITH_COMBINED_LLM_MODULES_QUERY, {
    variables: {
      viewId: existingViewId || '',
      viewDescription,
      objectTypeId
    },
    onError: error => {
      Raven.captureException(new Error('Update Ai view query failed'), {
        extra: {
          viewDescription,
          objectTypeId,
          viewId: existingViewId,
          error
        }
      });
      lazyFetchCreate({
        variables: {
          viewDescription,
          objectTypeId
        }
      });
    }
  });
  const fetchGeneratedView = useCallback(() => {
    if (isRegenerate && existingViewId) {
      //one Ai view exists, update it
      lazyFetchUpdate({
        variables: {
          viewId: existingViewId,
          viewDescription,
          objectTypeId
        }
      });
    } else {
      //no Ai view exists, create a new one
      lazyFetchCreate({
        variables: {
          viewDescription,
          objectTypeId
        }
      });
    }
  }, [isRegenerate, existingViewId, viewDescription, objectTypeId, lazyFetchCreate, lazyFetchUpdate]);
  return useMemo(() => ({
    generatedView: (updateData === null || updateData === void 0 ? void 0 : updateData.generateViewAndUpdateWithCombinedLlmModules) || (createData === null || createData === void 0 ? void 0 : createData.generateViewAndCreateWithCombinedLlmModules),
    loadingGeneratedView: createLoading || updateLoading,
    fetchGeneratedView,
    error: updateError || createError
  }), [createData, updateData, createLoading, updateLoading, fetchGeneratedView, createError, updateError]);
};