import { useHighlySensitivePropertyValues } from 'customer-data-properties/dataSensitivity/useHighlySensitivePropertyValues';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const useIsManageButtonVisible = ({
  objectTypeId,
  objectId,
  propertyDefinition,
  hovered
}) => {
  const highlySensitivePropertyValues = useHighlySensitivePropertyValues({
    objectTypeId,
    objectId,
    defaultValues: {}
  });

  // Hide the Manage button for Invoice properties since these should only
  // be modified through the Invoice editor, not directly in the table
  // https://hubspot.slack.com/archives/C042KSC7GLE/p1737989454892929
  if (objectTypeId === INVOICE_TYPE_ID) {
    return false;
  }
  if (propertyDefinition.dataSensitivity !== 'high') {
    return hovered;
  }
  const isHighlySensitiveValueRevealed = Object.prototype.hasOwnProperty.call(highlySensitivePropertyValues, propertyDefinition.name);
  return hovered && isHighlySensitiveValueRevealed;
};