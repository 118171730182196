import http from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
import { VIEWS_SEARCH_QUERY_NAME } from '../../viewsSearch/hooks/useFetchViewsSearch';
import { SINGLE_VIEW_BY_ID_QUERY_NAME } from '../../singleView/hooks/useFetchSingleView';
const DELETE_SINGLE_VIEW_BY_ID_MUTATION_NAME = 'deleteSingleViewById';
const deleteViewMutationArgsArgument = ['id'];
export const DeleteSingleViewMutation = registerMutation({
  fieldName: DELETE_SINGLE_VIEW_BY_ID_MUTATION_NAME,
  args: deleteViewMutationArgsArgument,
  fetcher: args => http.delete(`/sales/v4/views/${args.id}`)
});
export const useDeleteSingleView = ({
  viewId,
  objectTypeId
}) => {
  return useMutation(DeleteSingleViewMutation, {
    variables: {
      id: viewId
    },
    refetchQueries: [VIEWS_SEARCH_QUERY_NAME],
    awaitRefetchQueries: true,
    update(cache) {
      //the view would still remain in cache if we didnt manually remove this
      //
      //We should not do refetchQueries: [SINGLE_VIEW_BY_ID_QUERY_NAME] because that would refetch all queries for all calls to useFetchSingleView
      cache.evict({
        fieldName: SINGLE_VIEW_BY_ID_QUERY_NAME,
        args: {
          id: viewId,
          objectTypeId
        }
      });
      cache.gc();
    }
  });
};