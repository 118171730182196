import { useHasGate } from '../../userInfo/hooks/useHasGate';
import { gate } from 'hub-http/gates';
export const useCanGenerateAiViews = () => {
  const {
    loading: ViewGateLoading,
    data: canGenerateAiViews
  } = useHasGate(gate('CRM:Datasets:AiViewsIndex'));
  if (ViewGateLoading) {
    return false;
  }
  return canGenerateAiViews;
};