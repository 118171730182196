import { useQuery } from 'data-fetching-client';
import { GET_ACCESS_CONTROLS_QUERY } from '../../__generated__/chirp/com/hubspot/ai/access/controls/rpc/AiAccessControlsChirpService.dfc';
export function useGenerativeAIEnabled() {
  const {
    data
  } = useQuery(GET_ACCESS_CONTROLS_QUERY);
  return {
    isGenerativeAIEnabled: data === null || data === void 0 ? void 0 : data.getAccessControls.generativeAiEnabled,
    isCrmPropertiesEnabled: data === null || data === void 0 ? void 0 : data.getAccessControls.crmPropertiesEnabled
  };
}
export function useIsAiViewEnabled() {
  const {
    isGenerativeAIEnabled,
    isCrmPropertiesEnabled
  } = useGenerativeAIEnabled();
  return isGenerativeAIEnabled && isCrmPropertiesEnabled;
}