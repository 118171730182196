import styled from 'foundations-theming/utils';
export const GenericSkeleton = styled.div.withConfig({
  displayName: "GenericSkeleton",
  componentId: "sc-1sla6k2-0"
})(["max-width:100%;height:50%;background:linear-gradient( to right,", ",", " 20%,", " 40% );background-size:1200;"], ({
  theme
}) => theme.color['do-not-use-gypsum'], ({
  theme
}) => theme.color['do-not-use-koala'], ({
  theme
}) => theme.color['do-not-use-gypsum']);